import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    sectionContainer,
    grid,
    titleText,
    subtitleText,
    contentText,
    formContainer,
    image,
    contactInfoWrapper,
    contactInfoBlock,
    contactLink,
} from './contact.module.scss';

import { ISection } from '../../models/section.model';
import { ISocialLink } from '../../models/social-link.model';
import { IContactInfo } from '../../models/contact-info.model';
import { postContactForm } from '../../api-clients/contacts';
import usePostForm from '../../hooks/use-post-form';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import ContactInfo from '../molecules/contact-info';

interface IContactSection extends ISection {
    content: ISection['content'] & Record<'contactInfo', IContactInfo[]>;
    items: {
        socials: ISocialLink[];
    };
}

interface IContactProps {
    className?: string;
    section: IContactSection;
    TitleTag?: React.ElementType;
}

const Contact: React.FC<IContactProps> = ({ className = '', TitleTag, section }) => {
    const {
        sectionId,
        content: { texts, media, contactInfo },
        css,
        style,
    } = section;

    const { formikRef, handleSubmit, errorMessage, isSuccess, isLoading } =
        usePostForm(postContactForm);
    const t = useTranslations('Contact');

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionContainer} ${className}`}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
        >
            {texts[5] && texts[6] && (
                <a className={contactLink} href={`tel:${texts[6]}`}>
                    {texts[5]}
                </a>
            )}
            {texts[3] && texts[4] && (
                <a className={contactLink} href={`mailto:${texts[4]}`}>
                    {texts[3]}
                </a>
            )}
            {media.length > 0 && <Image className={image} aspectRatio="1" media={media} />}
            <div className={formContainer}>
                <div style={{width: '100%'}}><iframe width="100%" height="600" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Smugowa%2046A,%2003-032%20Warszawa+(Bohema)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
            </div>
        </Section>
    );
};

export default Contact;
