// extracted by mini-css-extract-plugin
export var contactInfoBlock = "contact-module--contact-info-block--f814f";
export var contactInfoWrapper = "contact-module--contact-info-wrapper--cb313";
export var contactLink = "contact-module--contact-link--81638";
export var contentText = "contact-module--content-text--276be";
export var form = "contact-module--form--71186";
export var formContainer = "contact-module--form-container--7ee17";
export var grid = "contact-module--grid--8c219";
export var heartIcon = "contact-module--heart-icon--e475a";
export var image = "contact-module--image--a460b";
export var sectionContainer = "contact-module--section-container--a163c";
export var subtitleText = "contact-module--subtitle-text--cdd82";
export var successContent = "contact-module--success-content--623f2";
export var successInfoContainer = "contact-module--success-info-container--02aa9";
export var successTitle = "contact-module--success-title--8cacd";
export var titleText = "contact-module--title-text--68d5e";