import { useRef } from 'react';
import { FormikConfig, FormikProps, FormikValues } from 'formik';
import { MutationFunction, useMutation } from '@tanstack/react-query';

import { handleFormikErrors, TAlterpressAxiosError } from '../utils/get-form-errors';
import useTranslations from './use-translations';

export default function usePostForm<FormInitialValues extends FormikValues, TData = unknown>(
    mutationFn: MutationFunction<TData, FormInitialValues>
) {
    const t = useTranslations('Api');
    const formikRef = useRef<FormikProps<FormInitialValues>>(null);
    const { mutate, ...mutation } = useMutation<TData, TAlterpressAxiosError, FormInitialValues>(
        mutationFn,
        {
            onError: (error) => {
                handleFormikErrors(formikRef.current, error, {
                    network: t.networkError,
                    global: t.unknownError,
                });
            },
        }
    );

    const handleSubmit: FormikConfig<FormInitialValues>['onSubmit'] = (values) => {
        mutate(values);
    };

    return {
        ...mutation,
        formikRef,
        handleSubmit,
        errorMessage: formikRef.current?.errors.global as string,
    };
}
